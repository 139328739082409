<template>
  <v-container fluid grid-list-lg class="pa-0">
    <SearchHeader :onAction="doSearch"></SearchHeader>

    <v-data-table :headers="headers" :items="projectTypes" :items-per-page="100" :search="search" class="elevation-1 ma-5">
      <template v-slot:item.action="{ item }">
        <v-icon @click="editItem(item)">
          mdi-account-edit
        </v-icon>
        <v-icon @click="deleteItem(item)">
          mdi-account-remove
        </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("common.list.noResults") }}
      </template>
    </v-data-table>

    <CfgPanel ref="cfgpanel" :onSave="save" :title="$t('projectTypes.edit.title')" :tabIndex="7003">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="projectType.name"
                :rules="rules.required"
                :label="$t('projectTypes.list.name')"
                required
                ref="firstfield"
                :tabIndex="7002"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <small>{{ $t("common.form.requiredText") }}</small>
            </v-col>
            <v-col cols="12">
              <v-alert text icon="mdi-help" border="left" color="green" block>
                {{ $t("projectTypes.edit.help") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </CfgPanel>
  </v-container>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin } from "@/helpers/mixins";

import CfgPanel from "@/components/_common/cfgpanel";
import SearchHeader from "@/components/_common/searchheader";

export default {
  mixins: [formatMixin, listMixin],

  data() {
    return {
      valid: false,
      show1: false,
      search: "",
      headers: [
        { text: this.$i18n.t("projectTypes.list.name"), align: "left", value: "name" },
        { text: this.$i18n.t("common.list.actions"), value: "action", sortable: false }
      ],
      projectTypes: [],
      editedIndex: -1,
      projectType: {},
      rules: {
        required: [v => !!v || this.$i18n.t("common.form.required")],
        email: [v => !!v || this.$i18n.t("common.form.requiredEmail"), v => /.+@.+\..+/.test(v) || this.$i18n.t("common.form.validEmail")],
        pwdvalidate: [v => !!v || this.$i18n.t("common.form.requiredEmail"), v => v == this.projectType.password || this.$i18n.t("common.form.identicalEmail")]
      }
    };
  },

  components: {
    CfgPanel,
    SearchHeader
  },

  created() {
    this.loadProjectTypes();
  },

  methods: {
    doSearch: function(val) {
      this.search = val
    },

    loadProjectTypes: function() {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/projecttypes`)
        .then(response => {
          this.projectTypes = response.data;
          this.$store.dispatch("loading", false);
        })
        .catch(e => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },

    reset: function() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },

    editItem: function(item) {
      this.$refs.cfgpanel.show();
      this.$refs.firstfield.focus();
      var u = null;
      this.editedIndex = -1;
      this.projectType = {};

      if (item) {
        u = this.projectTypes.find(projectType => {
          return projectType.id === item.id;
        });
        this.editedIndex = this.projectTypes.indexOf(u);
        if (this.editedIndex >= 0) {
          this.projectType = Object.assign({}, u);
        }
      }
    },

    deleteItem: function(item) {
      if (confirm("Are you sure you want to delete this Product type?")) {
        axios
          .delete(`/api/projecttypes/${item.id}`)
          .then(() => {
            this.loadProjectTypes();
          })
          .catch(e => {
            console.log(e);
          });
      }
    },

    save: function() {
      if (this.$refs.form.validate()) {
        let u = { ...this.projectType };

        axios
          .post(`/api/projecttypes/${u.id || ""}`, u)
          .then(msg => {
            this.updateItemList(this.projectTypes, msg.data);
            this.$refs.cfgpanel.hide();
          })
          .catch(e => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      }
    },
  }
};
</script>
