<template>
  <DefaultLayout>
    <template v-slot:header>
      <v-spacer />
      <AddButton :onClick="add" />
    </template>
    <v-layout>
      <v-flex>
        <ProjectTypeManager ref="projTypeManager" />
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default.vue";
import ProjectTypeManager from "@/components/projects/projecttypemanager.vue";
import AddButton from "@/components/_common/addbutton";

export default {
  name: "usermngt",
  components: {
    DefaultLayout,
    ProjectTypeManager,
    AddButton,
  },
  methods: {
    add: function() {
      this.$refs.projTypeManager.editItem();
    },
  },
};
</script>
